import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import ToolBarButton from "./ToolBarButton";
import PrimaryButton from "./PrimaryButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import AppsIcon from "@mui/icons-material/Apps";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import DownloadButton from "../../components/toolbar/DownloadButton";
import useSearchSYN from "../../common/hooks/useSearchSYN";
import ListItemText from "@mui/material/ListItemText";
import theme from '../../constants/theme';
import { LOTS_SUFFIX } from "../../constants/constants";

const useStyles = makeStyles(() => ({
  toolbar: {
    textAlign: "right",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
    width: window.innerWidth-theme.spacing(1),
    "& > *": {
      margin: theme.spacing(1),
      width: 130,
    },
  },
  selectWrapper: {
    "& > *": {
      visibility: "hidden",
    },
  },
}));

function BasicToolBar({
  allColumns,
  setAllFilters,
  searchBar,
  getDownloadData,
  handleResetFilter,
  toggleHideColumn,
}) {
  const classes = useStyles();
  const { toggleSearchSYN } = useSearchSYN();
  //for show hide columns
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  
  const selectAll = "Select all";
  const [checkedAll, setCheckedAll] = useState(false);
  const onSelectAll = (e) => {
    allColumns.map((column)=> 
      !(column["alwaysVisible"] != true && column.getToggleHiddenProps().checked == checkedAll) ? null : column.toggleHidden()
    )
    setCheckedAll(!checkedAll);
  };

  return (
    <div className={classes.toolbar}>
      {searchBar}
      <TextField
        select
        size="small"
        className={classes.selectWrapper}
        SelectProps={{
          multiple: true,
          value: allColumns,
          open: open,
          onClose: handleClose,
          onOpen: handleOpen,
        }}
      >
        <MenuItem value={selectAll} onClick={onSelectAll}>
          <Checkbox color="primary" checked={checkedAll} />
          <ListItemText primary={selectAll} />
        </MenuItem>
        {allColumns.map((column) =>
          column["alwaysVisible"] || column["isSlave"] ? null : (
            <MenuItem key={column.id} onClick={() => toggleHideColumn(column.id + LOTS_SUFFIX)}>
              <label>
                <Checkbox {...column.getToggleHiddenProps()} color="primary" />{" "}
                {column.Header}
              </label>
            </MenuItem>
          )
        )}
      </TextField>
      <ToolBarButton
        startIcon={<DeleteIcon />}
        onClick={() => handleResetFilter()}
      >
        Reset
      </ToolBarButton>
      <ToolBarButton
        className={classes.selectController}
        startIcon={<AppsIcon />}
        onClick={handleOpen}
      >
        Hide
      </ToolBarButton>
      <PrimaryButton
        startIcon={<SearchIcon />}
        onClick={() => {
          toggleSearchSYN();
        }}
      >
        Search
      </PrimaryButton>
      <DownloadButton getDownloadData={getDownloadData}></DownloadButton>
    </div>
  );
}

export default BasicToolBar;
