import React from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import theme from '../../constants/theme';
import {NumberFormatter} from "../../util/tableHelper";

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  table: {
    width: 400,
  },
  title: {
    flex: "1 1 100%",
  },
}));

const roundValue = (v) => {
  if(v === '-') return v;
  return v === 0 ? v : v.toFixed(2);
};

export default function CurrencyTable({ title, data }) {
  const classes = useStyles();
  const rows = Object.keys(data)
    .map((r) => ({
      currency: r,
      deposit: data[r].deposit,
      withdraw: data[r].withdraw,
      withdraw_apply: data[r].withdraw_apply,
      net: data[r].deposit + data[r].withdraw,
    }))
    .sort((a, b) =>
      a.currency === b.currency ? 0 : a.currency < b.currency ? -1 : 1
    );

  return (
    <div className={classes.root}>
      <TableContainer component={Paper}>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
        <Table
          className={classes.table}
          size="small"
          aria-label="currency table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Currency</TableCell>
              <TableCell align="right">Deposit</TableCell>
              <TableCell align="right">Withdraw</TableCell>
              <TableCell align="right">Withdraw(Apply)</TableCell>
              <TableCell align="right">Net Deposit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.currency}>
                <TableCell component="th" scope="row">
                  {row.currency}
                </TableCell>
                <TableCell align="right">{
                  ( row.currency == "BTC" ||
                    row.currency == "ETH" ) ?
                  ( NumberFormatter(row.deposit, 4, 4) ) :
                  ( NumberFormatter(row.deposit, 2, 2) )
                }</TableCell>
                <TableCell align="right">{
                  ( row.currency == "BTC" ||
                    row.currency == "ETH" ) ?
                  ( NumberFormatter(row.withdraw, 4, 4) ) :
                  ( NumberFormatter(row.withdraw, 2, 2) )
                }</TableCell>
                <TableCell align="right">{
                  ( row.currency == "BTC" ||
                    row.currency == "ETH" ) ?
                  ( NumberFormatter(row.withdraw_apply, 4, 4) ) :
                  ( NumberFormatter(row.withdraw_apply, 2, 2) )
                }</TableCell>
                <TableCell align="right">{
                  ( row.currency == "BTC" ||
                    row.currency == "ETH" ) ?
                  ( NumberFormatter(row.net, 4, 4) ) :
                  ( NumberFormatter(row.net, 2, 2) )
                }</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
