import "./App.scss";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { store } from "./createStore";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useLocation, useHistory } from "react-router-dom";
import AppWrapper from "./components/AppWrapper";
import APIErrorProvider from "./common/providers/APIErrorProvider";
import AUTHProvider from "./common/providers/AUTHProvider";
import SearchSYNProvider from "./common/providers/SearchSYNProvider";
import APIErrorNotification from "./components/APIErrorNotification";
import { WithAxios } from "./axios/WithAxios";
import Storage from "./common/lib/storage";
import { apiGetUserName } from "./resource/index";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import theme from './constants/theme';

function App() {
  let history = useHistory();
  let urlToken = new URLSearchParams(useLocation().search).get("token");
  let regulator = new URLSearchParams(useLocation().search).get("regulator");
  urlToken && Storage.saveToken(urlToken);
  regulator && Storage.saveRegulator(regulator);

  useEffect(() => {
    const getUserName = async () => {
      const { data } = (await apiGetUserName()).data;
      data.user.psp_role && Storage.savePSPRole(data.user.psp_role);
      data.user.name && Storage.saveUserName(data.user.name);
      data.user.blacklist && Storage.saveBlacklist(data.user.blacklist);
    };

    getUserName().then(() => {
      urlToken && history.push("/rebateReport");
    });
  }, [urlToken, regulator, history]);

  return (
    <Provider store={store}>
      <div className="App">
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <AUTHProvider>
                <APIErrorProvider>
                  <SearchSYNProvider>
                    <WithAxios>
                      <AppWrapper />
                      <APIErrorNotification />
                    </WithAxios>
                  </SearchSYNProvider>
                </APIErrorProvider>
              </AUTHProvider>
            </LocalizationProvider>
          </StyledEngineProvider>
        </ThemeProvider>
      </div>
    </Provider>
  );
}

export default App;
