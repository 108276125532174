import React from "react";
import { useDispatch } from "react-redux";
import AdvancedTable from "../../components/AdvancedTable";
import {
  StringComboFilter,
  NumberComboFilter,
  SelectMultipleColumnsFilter,
} from "../../components/filterTypes/filterTypes";
import { NumberFormatter } from "../../util/tableHelper";
import {
  apiGetCurrency,
  apiGetDataSource,
  apiDownloadFile,
  apiGetDownloadData,
  apiGetRebateData,
} from "../../resource/index.js";
import RebateSummary from "./RebateSummary";
import { RebateDetailLink, RebateDetailTable } from "./RebateDetail";
import Storage from "../../common/lib/storage";
import DateRange from "../../common/lib/dateRange";
import { useSelector } from "react-redux";
import * as types from "../../constants/actionTypes";
import { 
  LOTS_SUFFIX,
  DEFAULT_REBATE_STATE,
} from "../../constants/constants";
import useAPIError from "../../common/hooks/useAPIError";

export default function RebateReport() {
  const dispatch = useDispatch();
  const setDataSource = (val) => dispatch({ type: types.SET_DATA_SOURCE_LIST, value: val });
  const setRebateState = (val) => dispatch({ type: types.SET_REBATE_STATE, value: val });
  const setUserAndLevelName = (val) => dispatch({ type: types.SET_USER_AND_LEVEL_NAME, value: val });
  const setLevelId = (val) => dispatch({ type: types.SET_LEVEL_ID, value: val });
  const setShowAll = (val) => dispatch({ type: types.SET_SHOW_ALL, value: val });
  const { addError } = useAPIError();
  // start table without any data
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [noData, setNoData] = React.useState(true);
  const [count, setCount] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [currency, setCurrency] = React.useState([]);
  const [selectedSort, setSelectedSort] = React.useState({});
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const regulator = Storage.getRegulator() ?? "";
  const [dynamicColumns, setDynamicColumns] = React.useState([]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "clientName",
        disableSortBy: true,
        alwaysVisible: true,
        Filter: StringComboFilter,
        Cell: ({ row, cell }) => (
          <div style={{ textAlign: "left" }}>{cell.value}</div>
        ),
      },
      {
        Header: "Account",
        accessor: "mt4Account",
        disableSortBy: true,
        Filter: NumberComboFilter,
        alwaysVisible: true,
        Cell: ({ row, cell }) => (
          <div style={{ textAlign: "left" }}>{cell.value}</div>
        ),
      },
      {
        Header: "Currency",
        accessor: "currency",
        disableSortBy: true,
        alwaysVisible: true,
        Filter: ({ column }) =>
          SelectMultipleColumnsFilter({ column }, { dropdown: currency }),
        Cell: ({ row, cell }) => (
          <div style={{ textAlign: "left" }}>{cell.value}</div>
        ),
      },
      ...dynamicColumns,
    ],
    [currency, dynamicColumns]
  );

  const { filters } = useSelector((state) => state.report);
  
  const [detailOpen, setDetailOpen] = React.useState(false);
  const [detail, setDetail] = React.useState({});
  const renderGroupColumn = (group_name, isLots, isPamm) => {
    let { label, value } = group_name;

    if (isLots) {
      label += LOTS_SUFFIX;
      value += LOTS_SUFFIX;
    }

    if (isPamm) {
      // This is for special PU PAMM rebate column
      // as requested in ACM-17662
      return {
        alias: isLots? 'Lots' : 'Amount',
        Header: label,
        accessor: (d) => d[value],
        disableFilters: true,
        isSlave: false,
        Cell: ({ row, cell }) => (
          <RebateDetailLink
            value={NumberFormatter(cell.value, isLots?2:4, 4)}
            onClick={() => {
              setDetailOpen(true);
              setDetail({
                regulator,
                from: filters.fromDate,
                to: filters.toDate,
                agentAccount: row.values.mt4Account,
                groupName: group_name.value,
                dataSource: filters.dataSource,
                tradeOnCredit: filters.tradeOnCredit || "",
              });
            }}
            // This is added to handle the PU PAMM rebate
            // which do not require a link to click to
            // ACM-17662
            flag={false}
          />
        ),
      }
    }

    return {
      alias: isLots? 'Lots' : 'Amount',
      Header: label,
      accessor: (d) => d[value],
      disableFilters: true,
      isSlave: isLots,
      Cell: ({ row, cell }) => (
        <RebateDetailLink
          value={NumberFormatter(cell.value, isLots?2:4, 4)}
          onClick={() => {
            setDetailOpen(true);
            setDetail({
              regulator,
              from: filters.fromDate,
              to: filters.toDate,
              agentAccount: row.values.mt4Account,
              groupName: group_name.value,
              dataSource: filters.dataSource,
              tradeOnCredit: filters.tradeOnCredit || "",
            });
          }}
          flag={false}
        />
      ),
    }
  }

  const getTradingGroups = (tradingGroups) => {
    if (tradingGroups && tradingGroups.length > 0) {
      const headers = tradingGroups.filter(r => !r.label.includes(LOTS_SUFFIX)).map(group_name => {

        if (group_name.label == 'PAMM'){
          const pamm = renderGroupColumn(group_name, false, true)
          const lots = renderGroupColumn(group_name, true, true);
          return {
            Header: group_name.label,
            columns: [pamm, lots],
            disableFilters: true
          }
        } else {
          const amount = renderGroupColumn(group_name);
          const lots = renderGroupColumn(group_name, true);
          return {
            Header: group_name.label,
            columns: [amount, lots],
            disableFilters: true
          }
        }
      });
      
      return headers;
    }

    return [];
  };

  const downloadFile = (file) => {
    apiDownloadFile(file).then((res) => {
      const url = window.URL.createObjectURL(new Blob([
        new Uint8Array([0xEF, 0xBB, 0xBF]), // UTF-8 BOM
        "",
        res.data],
        { type: "text/plain;charset=utf-8" }
        ));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file);
      document.body.appendChild(link);
      link.click();
    });
  };

  const getDownloadData = () => {
    setLoading(true);
    apiGetDownloadData({
      regulator: regulator,
      sort: selectedSort,
      filter: selectedFilter ? selectedFilter : {},
      from: filters.fromDate || "",
      to: filters.toDate || "",
      dataSource: filters.dataSource || undefined,
      tradeOnCredit: filters.tradeOnCredit || "",
      levelId: filters.levelId || "",
      showAll: filters.showAll,
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          downloadFile(res.data.data.file);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    apiGetCurrency(regulator).then((res) => {
      if (res.data.status === "success") {
        setCurrency(res.data.data.currency);
      }
    });
    apiGetDataSource(regulator).then((res) => {
      if (res.data.status === "success") {
        setDataSource(res.data.data.dataSource);
      }
    });
  }, [regulator]);

  const fetchData = ({ pageSize, pageIndex, sortParams, filterParams }) => {
    if (DateRange.FindRangeError(filters.fromDate, filters.toDate)) {
      addError(DateRange.FindRangeError(filters.fromDate, filters.toDate));
      return;
    }

    setSelectedSort(sortParams);
    setSelectedFilter(filterParams);
    setLoading(true);
    setNoData(false);
    apiGetRebateData({
      regulator: regulator,
      pageNo: pageIndex + 1,
      pageSize: pageSize,
      sort: sortParams,
      filter: filterParams ? filterParams : {},
      from: filters.fromDate,
      to: filters.toDate,
      dataSource: filters.dataSource || undefined,
      tradeOnCredit: filters.tradeOnCredit || "",
      levelId: filters.levelId || "",
      showAll: filters.showAll,
    })
      .then((res) => {
        if (res.data.status === "success") {
          setDynamicColumns(getTradingGroups(res.data.data.tradingGroups));
          setData(res.data.data.rebate);
          setCount(res.data.data.total);
          setPageCount(Math.ceil(res.data.data.total / pageSize));
        }else{
          setNoData(true);
          setData([]);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setNoData(true);
      });
  };

  const invisibleColumns = ["CHINA50.v", "DAX30.v", "DJ30.v", "ES35.v",
  "EU50.v", "FRA40.v", "FTSE100.v", "HSI.v", "NAS100.v", "SP500.v", "SPI200.v",
  "US2000.v", "USDX.v", "VIX.v", "INDIA50.v", "SA40.v", "Bond"];

  const resetFilter = () => {
    setRebateState(DEFAULT_REBATE_STATE);
    setUserAndLevelName("");
    setLevelId("");
    setShowAll(true);
  }

  return (
    <div className="rebateReport">
      <AdvancedTable
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        noData={noData}
        count={count}
        pageCount={pageCount}
        getDownloadData={getDownloadData}
        getCustomFooter={(visibleColumns) => (
          <RebateSummary
            filterNames={['clientName', 'mt4Account', 'currency']}
            visibleColumns={visibleColumns}
            regulator={regulator}
            from={filters.fromDate}
            to={filters.toDate}
            dataSource={filters.dataSource}
            tradeOnCredit={filters.tradeOnCredit}
            levelId={filters.levelId}
            showAll={filters.showAll}
          />
        )}
        invisibleColumns={invisibleColumns.concat(invisibleColumns.map(r => r + LOTS_SUFFIX))}
        resetFilter={resetFilter}
      />
      <RebateDetailTable
        open={detailOpen}
        detail={detail}
        onClose={() => setDetailOpen(false)}
      />
    </div>
  );
}
