import React, { useState, useCallback, useEffect } from "react";
import { withStyles } from "@mui/styles";
import Link from "@mui/material/Link";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import DataGrid from "../../components/DataGrid";
import { apiGetRebateDetailData } from "../../resource/index.js";
import RebateDetailSymbol from "./RebateDetailSymbol";
import theme from "../../constants/theme";
import { NumberFormatter } from "../../util/tableHelper";
import DownloadButton from "../../components/toolbar/DownloadButton";
import { CSVLink } from "react-csv";
import moment from "moment";

const styles = () => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Typography variant="h6" component="div">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const RebateDetailLink = ({ value, onClick, flag }) => {
  return (
    <div style={{ textAlign: "left" }}>
      {flag ? (
        value
      ) : ( value && value !== "-" ? (
        <Link component="button" variant="body2" onClick={onClick}>
          {value}
        </Link>
      ) : (
        value
      ))}
    </div>
  );
};

const columns = [
  { selector: "agentName", name: "Account Owner", sortable: true },
  { selector: "mt4Account", name: "Account", sortable: true, width: "15%" },
  { selector: "clientName", name: "Name", sortable: true },
  {
    selector: "lots",
    name: "Lots",
    sortable: true,
    width: "12%",
    format: (row) => NumberFormatter(row.lots, 2, 2),
  },
  {
    selector: "notionalValue",
    name: "Notional Value",
    format: (row) => (row.notionalValue ? row.notionalValue : "N/A"),
    sortable: true,
  },
  { selector: "rebate", name: "Rebate", sortable: true },
];

const columns_pamm = [
  { selector: "agentName", name: "Account Owner", sortable: true },
  { selector: "mt4Account", name: "Account", sortable: true, width: "15%" },
  { selector: "clientName", name: "Name", sortable: true },
  { selector: 'symbol', name: 'Symbol', sortable: true },
  { selector: "rebate", name: "Rebate", sortable: true },
];

const customStyles = {
  headCells: {
    style: {
      fontSize: "1rem",
      fontWeight: "bold",
      backgroundColor: "#fafafa",
    },
  },
};

const headers = columns.map((r) => ({ label: r.name, key: r.selector }));
const headers_pamm = columns_pamm.map((r) => ({ label: r.name, key: r.selector }));

const Download = ({ data, out_headers }) => {
  let csvLink;
  const timestamp = moment().clone().format("YYYYMMDDHHmmss");
  const filename = `rebate_detail_report_${timestamp}.csv`;
  const getDownloadData = () => {
    csvLink.link.click();
  };

  return (
    <>
      <DownloadButton getDownloadData={getDownloadData} />
      <CSVLink
        style={{ display: "none" }}
        data={data}
        headers={out_headers}
        filename={filename}
        ref={(r) => (csvLink = r)}
      >
        Download
      </CSVLink>
    </>
  );
};

const RebateDetailTable = ({ open, detail, onClose }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { regulator, from, to, agentAccount, groupName, tradeOnCredit } =
    detail;

  const fetchData = useCallback(() => {
    setLoading(true);
    setRows([]);
    apiGetRebateDetailData({
      regulator,
      from,
      to,
      agentAccount,
      groupName,
      tradeOnCredit,
    })
      .then((res) => {
        if (res.data.status === "success") {
          setRows(res.data.data.detail);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [regulator, from, to, agentAccount, groupName, tradeOnCredit]);

  useEffect(() => {
    if (regulator) {
      fetchData();
    }
  }, [regulator, from, to, agentAccount, groupName, tradeOnCredit]);

  // Special condition for PAMM rebate column ACM-19866
  let out_columns = columns
  let out_headers = headers
  if (groupName == 'PAMM'){
    out_columns = columns_pamm
    out_headers = headers_pamm
  }

  return (
    <Dialog
      onClose={onClose}
      fullWidth={true}
      maxWidth="xl"
      aria-labelledby="account-title"
      open={open}
    >
      <DialogTitle id="account-title" onClose={onClose}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginRight: "40px",
          }}
        >
          <span>Account: {agentAccount}</span>
          <Download data={rows} out_headers={out_headers} />
        </div>
      </DialogTitle>
      <DialogContent>
        <DataGrid
          columns={out_columns}
          data={rows}
          // Special condition for PAMM rebate column ACM-19866
          ExpandableComponent={({ data }) => {if (groupName == 'PAMM') {
              return null
            } else {
              return (
              <RebateDetailSymbol detail={detail} mt4Account={data.mt4Account} />
            )}
          }}
          progressPending={loading}
          props={{
            noHeader: true,
            pointerOnHover: true,
            expandOnRowClicked: true,
            customStyles,
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export { RebateDetailLink, RebateDetailTable };
