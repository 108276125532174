import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export default function CampaignDetail({ data }) {
  return (
    <div>
      <List dense>
        <ListItem>
          <ListItemText
            primary={`Referree ID: ${data.referee_id}`}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={`Referree Name: ${data.referee_name}`}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={`Referrer ID: ${data.referrer_id}`}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={`Referrer Name: ${data.referrer_name}`}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={`Status: ${data.status}`}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={`Waiting Condition: ${data.waiting_condition || ''}`}
          />
        </ListItem>
      </List>
    </div>
  );
}