import React from "react";
import clsx from "clsx";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { withRouter, Switch, Route, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as types from '../constants/actionTypes';
import Routes from "./Routes";
import Storage from "../common/lib/storage";
import { VT_HOST } from "../config/config";
import { ROUTES } from "../constants/constants";
import Menu from "./Menu";
import ReportFilter from "./ReportFilter";
import theme from '../constants/theme';

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  customToolBar: {
    display: "flex",
  },
  title: {
    flexGrow: 1,
    textAlign: "left",
  },
  toolBarItems: {
    flexGrow: 0,
    marginLeft: "8%",
    justifyContent: "space-between",
  },
}));

function AppWrapper(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(true);
  const channelPermitted = Storage.getPSPRole();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <>
      <Divider />
      <ReportFilter />
    </>
  );

  const location = useLocation();
  const dispatch = useDispatch();
  const setFilters = (filters) => dispatch({ type: types.SET_FILTERS, filters });

  React.useEffect(() => {
    // reset various filters
    setFilters({});
  }, [location]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: mobileOpen,
        })}
      >
        <Toolbar className={classes.customToolBar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={clsx(classes.menuButton, mobileOpen && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            className={classes.title}
          >
            DaVinci Report Service
          </Typography>
          <Menu />
          <Button color="inherit" className={classes.toolBarItems}>
            {document.location.host === VT_HOST ||
            document.location.pathname === ROUTES.CHANNEL_DATA
              ? ""
              : Storage.getRegulator() ?? ""}
          </Button>
          <Button
            color="inherit"
            className={classes.toolBarItems}
            data-testid="appbar-username"
          >
            <AccountCircle fontSize="large" /> {Storage.getUserName() ?? ""}
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="persistent"
        anchor={"left"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
      >
        <div className={classes.drawerHeader}>
          Filter
          <IconButton onClick={handleDrawerToggle}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        {drawer}
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: mobileOpen,
        })}
      >
        <div className={classes.drawerHeader} />
        <Switch>
          {Routes.map((route) => {
            if (route.PSPRoleRequired && !channelPermitted) return null;
            return (
              <Route exact path={route.path} key={route.path}>
                <route.component />
              </Route>
            );
          })}
        </Switch>
      </main>
    </div>
  );
}

export default withRouter(AppWrapper);
