import { ROUTES } from "../constants/constants";
import RebateReport from "../pages/RebateReport/RebateReport";
import RebateFilter from '../pages/RebateReport/RebateFilter';
import DepositReport from "../pages/DepositReport/DepositReport";
import DepositFilter from "../pages/DepositReport/DepositFilter";
import CampaignReport from "../pages/CampaignReport/CampaignReport";
import ChannelData from "../pages/ChannelData/ChannelData";
import FTDeposit from "../pages/FTDeposit/FTDepositReport";
import FTDepositFilter from "../pages/FTDeposit/DepositFilter";
import TradeReport from "../pages/TradeReport/TradeReport";
import TradeFilter from "../pages/TradeReport/TradeFilter";

const Routes = [
  {
    path: "/",
    component: RebateReport,
    filter: RebateFilter
  },
  {
    path: ROUTES.REBATE_REPORT,
    component: RebateReport,
    filter: RebateFilter
  },
  {
    path: ROUTES.DEPOSIT_REPORT,
    component: DepositReport,
    filter: DepositFilter
  },
  {
    path: ROUTES.TRADE_REPORT,
    component: TradeReport,
    filter: TradeFilter
  },
  {
    path: ROUTES.FTD_DEPOSIT,
    component: FTDeposit,
    filter: FTDepositFilter
  },
  {
    path: ROUTES.CAMPAIGN_REPORT,
    component: CampaignReport,
  },
  {
    path: ROUTES.CHANNEL_DATA,
    PSPRoleRequired: true,
    component: ChannelData,
  }
];

export default Routes;
