const _token = 'token';
const _regulator = 'regulator';
const _userName = "username";
const _PSPRole = "psp_role";
const _blacklist = "blacklist";

class Storage {
  /**
   * Returns a authorisation token
   *
   * @return {string} token to insert in the reqeust header
   */
  static getToken() {
    return sessionStorage.getItem(_token);
  }

  /**
   * * @param {string} value the token value
   *
   * @save token
   */
  static saveToken(value) {
    return sessionStorage.setItem(_token, value);
  }

  /**
   * * @delete token
   */
  static deleteToken() {
    return sessionStorage.removeItem(_token);
  }

  /**
   * Returns a regulator string
   *
   * @return {regulator} string to send to the back-end
   */
  static getRegulator() {
    return sessionStorage.getItem(_regulator);
  }

  /**
   * * @param {string} value the regulator value
   *
   * @save regulator
   */
  static saveRegulator(value) {
    return sessionStorage.setItem(_regulator, value);
  }

  /**
   * * @delete regulator
   */
  static deleteRegulator() {
    return sessionStorage.removeItem(_regulator);
  }

  /**
   * Returns a username string
   *
   * @return {username} string
   */
  static getUserName() {
    return sessionStorage.getItem(_userName);
  }

  /**
   * * @param {string} value the username
   *
   * @save username
   */
  static saveUserName(value) {
    return sessionStorage.setItem(_userName, value);
  }

  /**
   * * @delete regulator
   */
  static deleteUserName() {
    return sessionStorage.removeItem(_userName);
  }

  /**
  * * @param {string} value the psp_role
  *
  * @save psp_role
  */
  static savePSPRole(value) {
    return sessionStorage.setItem(_PSPRole, value);
  }

  /**
  * Returns a psp_role string
  *
  * @return {psp_role} string
  */
  static getPSPRole() {
    return sessionStorage.getItem(_PSPRole);
  }

  /**
   * * @param {string} value the blacklist
   *
   * @save blacklist
   */
  static saveBlacklist(value) {
    return sessionStorage.setItem(_blacklist, JSON.stringify(value));
  }

  /**
  * Returns a blacklist of pages
  *
  * @return {Array} blacklist
  */
  static getBlacklist() {
    return JSON.stringify(sessionStorage.getItem(_blacklist));
  }
}
export default Storage;