import React, { useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import MonetizationOn from "@mui/icons-material/MonetizationOn";
import GroupAdd from "@mui/icons-material/GroupAdd";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FileUpload from "@mui/icons-material/FileUpload";
import { ROUTES } from "../constants/constants";
import { withStyles } from "@mui/styles";
import { useHistory, useLocation } from "react-router-dom";
import Storage from "../common/lib/storage";
import theme from '../constants/theme';

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function CustomizedMenus() {
  let history = useHistory();
  const channelPermitted = Storage.getPSPRole();
  const blacklist = Storage.getBlacklist();

  const routeList = [
    {
      id: ROUTES.REBATE_REPORT,
      text: "Rebate Report",
      icon: <EqualizerIcon />,
      onClick: () => history.push(ROUTES.REBATE_REPORT),
    },
    {
      id: ROUTES.TRADE_REPORT,
      text: "Trade Report",
      icon: <AutoStoriesIcon />,
      onClick: () => history.push(ROUTES.TRADE_REPORT),
    },
    {
      id: ROUTES.DEPOSIT_REPORT,
      text: "Deposit/Withdraw Report",
      icon: <MonetizationOn />,
      onClick: () => history.push(ROUTES.DEPOSIT_REPORT),
    },
    {
      id: ROUTES.FTD_DEPOSIT,
      text: "First Time Deposit",
      icon: <ExitToAppIcon />,
      onClick: () => history.push(ROUTES.FTD_DEPOSIT),
    },
    {
      id: ROUTES.CAMPAIGN_REPORT,
      text: "Campaign Report",
      icon: <GroupAdd />,
      onClick: () => history.push(ROUTES.CAMPAIGN_REPORT),
    },
  ]

  channelPermitted &&
  routeList.push({
      id: ROUTES.CHANNEL_DATA,
      text: "Channel Data",
      icon: <FileUpload />,
      onClick: () => history.push(ROUTES.CHANNEL_DATA),
    });

  const itemsList = routeList.filter(r => !blacklist.includes(r.id));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const curr = useLocation();

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  useEffect(() => {
    let currIndex = 0;

    if (curr && curr.pathname) {
      const i = itemsList.map((r) => r.id).indexOf(curr.pathname);
      if (i > -1) {
        currIndex = i;
      }
    }

    if (currIndex !== selectedIndex) {
      setSelectedIndex(currIndex);
    }
  }, [curr]);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List component="nav" aria-label="Report Navigation">
        <ListItem button aria-haspopup="true" onClick={handleClickListItem}>
          <ListItemText primary={itemsList[selectedIndex].text} />
          <ArrowDropDown />
        </ListItem>
      </List>
      <StyledMenu
        id="report-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {itemsList.map((item, index) => {
          const { text, icon, onClick } = item;
          return (
            <StyledMenuItem
              key={index}
              selected={index === selectedIndex}
              onClick={(event) => {
                handleMenuItemClick(event, index);
                onClick();
              }}
            >
              {icon && <ListItemIcon>{icon}</ListItemIcon>}
              <ListItemText primary={text} />
            </StyledMenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
}
