import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Mt4Select from "../../components/searchBar/Mt4Select";
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SelectFilter from '../../components/searchBar/SelectFilter';
import CountrySelect from '../../components/searchBar/CountrySelect';
import DateRangeFilter from '../../components/searchBar/DateRangeFilter';
import {
  ACCOUNT_TYPES,
  DEFAULT_DEPOSITATE_STATE,
} from "../../constants/constants";
import * as types from '../../constants/actionTypes';
import AddCircle from '@mui/icons-material/AddCircle';
import PaymentMethod from './PaymentMethod';
import PaymentStatus from './PaymentStatus';
import PaymentChannel from './PaymentChannel';
import UserLevelFilter from "../../components/searchBar/UserLevelFilter";
import { makeStyles } from "@mui/styles";
import theme from '../../constants/theme';


const useStyles = makeStyles(() => ({
  formControl: {
    margin: theme.spacing(1),
    display: "flex",
    fontStyle: "italic",
  },
  selectControl: {
    width: 250,
  },
  selectedFilters: {
    display: "flex",
    flexWrap: "wrap",
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const DepositFilter = () => {
  const classes = useStyles();
  const { filters, levelId, showAll, userAndLevelName, mt4AccountList, seletedMt4AccountList, countryList, selectedCountries, deposityState } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const setFilters = (filters) => dispatch({ type: types.SET_FILTERS, filters });
  const selectCountries = (val) => dispatch({ type: types.SET_SELECTED_COUNTRIES, value: val });
  const setDeposityState = (val) => dispatch({ type: types.SET_DEPOSITY_STATE, value: val });
  const setSelectedMt4Accounts = (val) => dispatch({ type: types.SET_SELECTED_MT4_ACCOUNT_LIST, value: val });
  const setSelectedContinents = (val) => dispatch({ type: types.SET_CONTINENTS_LIST, value: val });
  const setUserAndLevelName = (val) => dispatch({ type: types.SET_USER_AND_LEVEL_NAME, value: val });
  const setLevelId = (val) => dispatch({ type: types.SET_LEVEL_ID, value: val });
  const setShowAll = (val) => dispatch({ type: types.SET_SHOW_ALL, value: val });

  const filterList = [
    {
      label: "Date Range",
      value: "dateRange",
      keys: ["fromDate", "toDate"],
      required: true,
    },
    {
      label: "User & Level",
      value: "userAndLeve",
      keys: ["userAndLeve"],
      required: true,
    },
    {
      label: "Account Type",
      value: "accountType",
      keys: ["accountType"],
      required: true,
    },
    {
      label: "Country",
      value: "country",
      keys: ["countryCodes"],
      show: true,
    },
    {
      label: "MT4 Account",
      value: "mt4Account",
      keys: ["mt4Account"],
      show: true,
    },
    {
      label: "Payment Status",
      value: "paymentStatus",
      keys: ["paymentStatus"],
      show: true,
    },
    {
      label: "Payment Method",
      value: "paymentMethod",
      keys: ["paymentMethod"],
      show: true,
    },
    {
      label: "Payment Channel",
      value: "paymentChannel",
      keys: ["paymentChannel"],
      show: true,
    },
  ];

  const [selectedFilters, setSelectedFilters] = useState(
    filterList.filter((r) => r.required || r.show).map((r) => r.value)
  );

  const toggleFilter = (val) => {
    setSelectedFilters(val);
    // reset filter value if it's not selected
    const filterKeys = filterList
      .filter((r) => val.includes(r.value))
      .flatMap((r) => r.keys);
    const newFilters = { ...deposityState};
    Object.keys(deposityState).forEach((r) => {
      if (!(filterKeys.includes(r) || ['withdraw','deposit'].includes(r))) {
        newFilters[r] = DEFAULT_DEPOSITATE_STATE[r];
      }
    });
    setDeposityState(newFilters)
    // reset countryCodes
    if (!filterKeys.includes('countryCodes')) {
      selectCountries([]);
      setSelectedContinents([]);
    }
    if (!filterKeys.includes('mt4Account')) {
      setSelectedMt4Accounts([]);
    }
  };

  const isVisible = (id) => {
    return selectedFilters.includes(id);
  };

  const selectedFilterCount = selectedFilters.length;

  const onClickWithdraw = e => {
    setDeposityState({ ...deposityState, 'withdraw': !deposityState['withdraw']});
  };
  const onClickDeposit = e => {
    setDeposityState({ ...deposityState, 'deposit': !deposityState['deposit']});
  };
  
  useEffect(() => {
    const {
      fromDate,
      toDate,
      accountType,
      deposit,
      withdraw,
      depositDataType,
      depositPaymentStatus,
      depositPaymentMethod,
      depositPaymentChannel,
      withdrawDataType,
      withdrawPaymentStatus,
      withdrawPaymentMethod,
      withdrawPaymentChannel
    } = deposityState;

    const codes = selectedCountries.map(r => r.value).join(',');
    setFilters({
      fromDate,
      toDate,
      accountType,
      deposit,
      withdraw,
      depositDataType,
      depositPaymentStatus,
      depositPaymentMethod,
      depositPaymentChannel,
      withdrawDataType,
      withdrawPaymentStatus,
      withdrawPaymentMethod,
      withdrawPaymentChannel,
      countryCodes: codes || undefined,
      levelId,
      showAll
    });
  }, [deposityState, selectedCountries, levelId, showAll]);

  return (
    <>
      <div className={classes.formControl}>
        <InputLabel id="filter-mutiple-checkbox-label">
          <AddCircle color="primary" />
        </InputLabel>
        <Select
          className={classes.selectControl}
          labelId="filter-mutiple-checkbox-label"
          id="filter-mutiple-checkbox"
          multiple
          value={selectedFilters}
          onChange={(e) => toggleFilter(e.target.value)}
          input={<Input />}
          renderValue={(selected) => `${selectedFilterCount} filters selected`}
        >
          {filterList.map((option) => (
            <MenuItem
              key={option.value}
              disabled={option.required}
              value={option.value}
            >
              <Checkbox
                color="primary"
                checked={selectedFilters.includes(option.value)}
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </div>
      <DateRangeFilter
        selectedFromDate={deposityState.fromDate}
        setSelectedFromDate={(val) => setDeposityState({ ...deposityState, fromDate: val })}
        selectedToDate={deposityState.toDate}
        setSelectedToDate={(val) => setDeposityState({ ...deposityState, toDate: val })}
      />

      {isVisible("userAndLeve") && (
          <UserLevelFilter 
            levelId={levelId}
            setLevelId={setLevelId}
            showAll={showAll}
            setShowAll={setShowAll}
            userAndLevelName={userAndLevelName}
            setUserAndLevelName={setUserAndLevelName}
          />
        )}

      <SelectFilter
        size="small"
        select
        variant="outlined"
        label="Account Type"
        InputLabelProps={{
          shrink: true,
        }}
        SelectProps={{
          value: deposityState.accountType || "",
          onChange: (e) =>
            setDeposityState({ ...deposityState, accountType: e.target.value || "" }),
          displayEmpty: true,
        }}
      >
        <MenuItem value="">
          <em>All Account Types</em>
        </MenuItem>
        <MenuItem value={ACCOUNT_TYPES.TRADING_ACCOUNT}>
          Trading Account
        </MenuItem>
        <MenuItem value={ACCOUNT_TYPES.REBATE_ACCOUNT}>Rebate Account</MenuItem>
      </SelectFilter>

      {isVisible("mt4Account") && (
      <Mt4Select
          data={mt4AccountList}
          onChange={(val) => setSelectedMt4Accounts(val)}
          value={seletedMt4AccountList}
      />
      )}

      {isVisible("country") && (
        <CountrySelect
           data={countryList}
           onChange={(val) => selectCountries(val)}
           value={selectedCountries}
         />
      )}
    <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
      <ListItem button onClick={onClickDeposit}>
        <ListItemIcon>
          <Checkbox className={classes.checkbox} checked={deposityState.deposit} color="primary"/>
        </ListItemIcon>
        <ListItemText primary="Deposit" />
        {deposityState.deposit ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={deposityState.deposit} timeout="auto" unmountOnExit>
      {isVisible('paymentStatus') && (<PaymentStatus
        value={deposityState.depositPaymentStatus}
        dataType={deposityState.depositDataType}
        onChange={(val) => setDeposityState({ ...deposityState, depositPaymentStatus: val})}
      />)}
      {isVisible('paymentMethod') && (<PaymentMethod
          value={deposityState.depositPaymentMethod}
          dataType={deposityState.depositDataType}
          onChange={(val) => setDeposityState({ ...deposityState, depositPaymentMethod: val, depositPaymentChannel: ''})}
      />)}
      {isVisible('paymentChannel') && (<PaymentChannel
          value={deposityState.depositPaymentChannel}
          dataType={deposityState.depositDataType}
          paymentMethod={deposityState.depositPaymentMethod}
          onChange={(val) => setDeposityState({ ...deposityState, depositPaymentChannel: val})}
      />)}
      </Collapse>
      <ListItem button onClick={onClickWithdraw}>
        <ListItemIcon>
          <Checkbox className={classes.checkbox} checked={deposityState.withdraw} color="primary"/>
        </ListItemIcon>
        <ListItemText primary="Withdraw" />
        {deposityState.withdraw ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={deposityState.withdraw} timeout="auto" unmountOnExit>
        {isVisible('paymentStatus') && (<PaymentStatus
            value={deposityState.withdrawPaymentStatus}
            dataType={deposityState.withdrawDataType}
            onChange={(val) => setDeposityState({ ...deposityState, withdrawPaymentStatus: val})}
        />)}
        {isVisible('paymentMethod') && (<PaymentMethod
            value={deposityState.withdrawPaymentMethod}
            dataType={deposityState.withdrawDataType}
            onChange={(val) => setDeposityState({ ...deposityState, withdrawPaymentMethod: val, withdrawPaymentChannel: ''})}
        />)}
        {isVisible('paymentChannel') && (<PaymentChannel
            value={deposityState.withdrawPaymentChannel}
            dataType={deposityState.withdrawDataType}
            paymentMethod={deposityState.withdrawPaymentMethod}
            onChange={(val) => {
              setDeposityState({ ...deposityState, withdrawPaymentChannel: val})
            }}
        />)}
      </Collapse>
    </List>
    {/* <pre>{JSON.stringify(deposityState, null, 2)}</pre> */}
    </>
  );
};

export default DepositFilter;
