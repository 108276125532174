import React from "react";
import { useSelector, useDispatch } from "react-redux";
import AdvancedTable from "../../components/AdvancedTable";
import {
  StringComboFilter,
  NumberComboFilter,
  SelectMultipleColumnsFilter,
} from "../../components/filterTypes/filterTypes";
import {
  apiGetCurrency,
  apiGetCountry,
  apiGetMt4Account,
  apiDownloadFile,
  apiGetFTDeposit,
  apiFTDepositDownload,
  apiGetCurrencyPrice
} from "../../resource/index.js";
import Storage from "../../common/lib/storage";
import DateRange from "../../common/lib/dateRange";
import * as types from '../../constants/actionTypes';
import { ACCOUNT_TYPES } from '../../constants/constants';
import useAPIError from "../../common/hooks/useAPIError";
import {NumberFormatter} from "../../util/tableHelper";
import { DEFAULT_FTD_STATE } from "../../constants/constants";
import FTDSummary from './FTDSummary'

export default function FTDeposit() {
  const { seletedMt4AccountList } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const setCountry = (val) => dispatch({ type: types.SET_COUNTRY_LIST, value: val});
  const setFtdState = (val) => dispatch({ type: types.SET_FTD_STATE, value: val });
  const setMt4Account = (val) => dispatch({ type: types.SET_MT4_ACCOUNT_LIST, value: val});
  //const setSelectedCountry = (val) => dispatch({ type: types.SET_SELECTED_COUNTRIES, value: val});
  const setSelectedMt4Accounts = (val) => dispatch({ type: types.SET_SELECTED_MT4_ACCOUNT_LIST, value: val });
  const selectCountries = (val) => dispatch({ type: types.SET_SELECTED_COUNTRIES, value: val });
  const setSelectedContinents = (initState) => dispatch({ type: types.SET_CONTINENTS_LIST, value: initState });
  const setUserAndLevelName = (val) => dispatch({ type: types.SET_USER_AND_LEVEL_NAME, value: val });
  const setLevelId = (val) => dispatch({ type: types.SET_LEVEL_ID, value: val });
  const setShowAll = (val) => dispatch({ type: types.SET_SHOW_ALL, value: val });
  const { addError } = useAPIError();
  const [currency, setCurrency] = React.useState([]);
  // start table without any data
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [noData, setNoData] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [selectedSort, setSelectedSort] = React.useState({});
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const regulator = Storage.getRegulator() ?? "";
  const { filters } = useSelector(state => state.report);
  const isTradingAccount = filters.accountType === ACCOUNT_TYPES.TRADING_ACCOUNT;
  const [summary, setSummary] = React.useState([]);

  const columns = React.useMemo(
    () =>
      [
        {
          Header: "Agent",
          accessor: "ib_name",
          disableSortBy: false,
          disableFilters: false,
          Filter: StringComboFilter,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Client Name",
          accessor: "user_name",
          disableSortBy: true,
          disableFilters: false,
          Filter: StringComboFilter,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Account",
          accessor: "mt4_account",
          disableSortBy: true,
          disableFilters: false,
          Filter: NumberComboFilter,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Currency",
          accessor: "currency",
          disableSortBy: false,
          Filter: ({ column }) =>
            SelectMultipleColumnsFilter({ column }, { dropdown: currency }),
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Deposit",
          accessor: "actual_amount",
          id: 'actual_amount',
          disableSortBy: false,
          disableFilters: true,
          alwaysVisible: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{
              ( row.original.currency == "BTC" ||
                row.original.currency == "ETH" ) ?
              ( NumberFormatter(cell.value, 4, 4) ) :
              ( NumberFormatter(cell.value, 2, 2) )
            }</div>
          ),
        },
        {
          Header: "Processed Time",
          accessor: "update_time",
          disableSortBy: false,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Type",
          accessor: "payment_type_text",
          id:"payment_type",
          disableSortBy: true,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Country",
          accessor: "country",
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
        {
          Header: "Client Create Time",
          accessor: "clientCreateTime",
          disableSortBy: false,
          disableFilters: true,
          show: true,
          Cell: ({ row, cell }) => (
            <div style={{ textAlign: "left" }}>{cell.value}</div>
          ),
        },
      ], [currency, isTradingAccount]);

  const downloadFile = (file) => {
    apiDownloadFile(file)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([
          new Uint8Array([0xEF, 0xBB, 0xBF]), // UTF-8 BOM
          "",
          res.data],
          { type: "text/plain;charset=utf-8" }
          ));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file);
        document.body.appendChild(link);
        link.click();
      })
  };

  const getDownloadData = () => {
    setLoading(true);
    apiFTDepositDownload({
      regulator: regulator,
      sort: selectedSort,
      filter: selectedFilter ? selectedFilter : {},
      from: filters.fromDate || "",
      to: filters.toDate || "",
      accountType: filters.accountType || undefined,
      countryCodes: filters.countryCodes || undefined,
      paymentMethod: filters.paymentMethod || undefined,
      paymentChannel: filters.paymentChannel || undefined,
      levelId: filters.levelId || "",
      showAll: filters.showAll,
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          downloadFile(res.data.data.file);
        }
      })
      .catch(() => {
        setLoading(false);
	  })
  };

  const getData = React.useCallback(async () => {
    try {
      const currency_res = (await apiGetCurrency(regulator)).data;
      setCurrency(currency_res.data.currency);

      const country_res = (await apiGetCountry(regulator)).data;
      setCountry(country_res.data.country);

      const mt4_account_res = (await apiGetMt4Account(regulator)).data.data.accountTypes;
      setMt4Account(Object.keys(mt4_account_res).map(key => { return {"label": key, "value": mt4_account_res[key]}}).filter((record) => record.value > 0 ));
      const currencyList = currency_res.data.currency.map(r => r.value).join(',');
      const price_res = (await apiGetCurrencyPrice(regulator, currencyList)).data;
      const currency_prices = {};
      price_res.data.price.forEach(r => {
        const key = r.symbol.replace('USD', '');

        if (r.symbol.indexOf('USD') === 0) {
          currency_prices[key] = 1 / r.price;
        } else {
          currency_prices[key] = r.price;
        }
      })
    } catch (err) {
      setMt4Account([]);
      setCurrency([]);
      setCountry([]);
    }
  }, [regulator]);

  React.useEffect(() => {
    if (regulator) {
        getData();
    }
  }, [regulator]);

  const fetchData = ({ pageSize, pageIndex, sortParams, filterParams }) => {
    if (DateRange.FindRangeError(filters.fromDate, filters.toDate)) {
      addError(DateRange.FindRangeError(filters.fromDate, filters.toDate));
      return;
    }

    const filterOptions = {
      currency: filterParams.currency || undefined,
      agent: filterParams.ib_name || undefined,
      clientName: filterParams.user_name || undefined,
      account: filterParams.mt4_account && filterParams.mt4_account["="] ? filterParams.mt4_account["="] : (filterParams.mt4_account || undefined),
      accounttype: seletedMt4AccountList ? seletedMt4AccountList.map(r => r.value) : undefined || undefined,
    }
    setSelectedSort(sortParams);
    setSelectedFilter(filterOptions);
    setLoading(true);
    setNoData(false);
    apiGetFTDeposit({
      regulator: regulator,
      pageNo: pageIndex + 1,
      pageSize: pageSize,
      sort: sortParams,
      filter: filterOptions ? filterOptions : {},
      from: filters.fromDate,
      to: filters.toDate,
      accountType: filters.accountType || undefined,
      countryCodes: filters.countryCodes || undefined,
      paymentMethod: filters.paymentMethod || undefined,
      paymentChannel: filters.paymentChannel || undefined,
      levelId: filters.levelId || "",
      showAll: filters.showAll,
    })
      .then((res) => {
        if (res.data.status === "success") {
          setData(res.data.data.first_time_deposit);
          setCount(res.data.data.total);
          setPageCount(Math.ceil(res.data.data.total / pageSize));
          setSummary(res.data.data.summary);
        }else{
          setNoData(true);
          setData([]);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setNoData(true);
        setSummary([]);
      })
  }

  const resetFilter = () => {
    selectCountries([]);
    setSelectedContinents([]);
    setFtdState(DEFAULT_FTD_STATE);
    setSelectedMt4Accounts([]);
    setUserAndLevelName("");
    setLevelId("");
    setShowAll(true);
  }

  return (
    <div className="ftDeposit">
      <AdvancedTable
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        noData={noData}
        count={count}
        pageCount={pageCount}
        getDownloadData={getDownloadData}
        resetFilter={resetFilter}
        getCustomFooter={() => (
          <FTDSummary
            data = {summary}
          />
        )}
      />
    </div>
  );
}
